import React from 'react';
import { ApiDataProvider } from './Context/ApiDataContext';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UrlKeywordInput } from './Components/UrlKeywordInput.js';
import ApiOutputTable from "./Components/ApiOutputTable";


function App() {
    return (
        <ApiDataProvider>
            <ChakraProvider>
                <Router>
                    <div className="App">
                        <Routes>
                            <Route path="/" element={<UrlKeywordInput />} exact />
                            <Route path="/table" element={<ApiOutputTable />} />
                        </Routes>
                    </div>
                </Router>
            </ChakraProvider>
        </ApiDataProvider>
    );
}

export default App;
