import { Fragment, useRef, useState, useEffect } from "react";
import { useApiData } from "../Context/ApiDataContext";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import ErrorModal from './ErrorModal';
import { CheckIcon, CpuChipIcon } from "@heroicons/react/24/outline";
import ProgressLine from "./ProgressLine";
import LoaderImage from "../assets/ai-loader.gif";
import {
  Button,
  Stack,
  Input,
  Text,
  InputGroup,
  FormControl,
} from "@chakra-ui/react";
import { LinkIcon, EnvelopeIcon } from "@heroicons/react/20/solid";

export function UrlKeywordInput() {
  const {
    fetchApiData,
    url, setUrl,
    password, setPassword,
    errorModalOpen, setErrorModalOpen,
    errorModalMessage, setErrorModalMessage,
      userLoggedIn, setUserLoggedIn,
  } = useApiData();
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(false);


  // console.log("errorModalOpen:", errorModalOpen);
  //   console.log("errorModalMessage in inputs:", errorModalMessage);
  //   console.log("userLoggedIn in inputs:", userLoggedIn);
  //   console.log('document.cookie:', document.cookie);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const cancelButtonRef = useRef(null);

    const handleContinue = async (e) => {
      e.preventDefault();
      openModal();

      const success = await fetchApiData();
      if (success) {
        console.log('Authentication successful');
        document.cookie = `loggedIn=${password}`;
        navigate("/table");
      } else {
        closeModal();
        console.error("Fetching data failed:");
        setErrorModalMessage("Internal server error: Incorrect Password");
        setErrorModalOpen(true);
      }
    };


  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black text-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                  <img src={LoaderImage} className="m-auto"/>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-100"
                      >
                        Identifying Placements
                      </Dialog.Title>
                      <ProgressLine
                        visualParts={[
                          {
                            percentage: "100%",
                            color: "linear-gradient(58deg, rgba(39,73,189,1) 0%, rgba(116,223,221,1) 100%)",
                          },
                        ]}
                      />
                      <div className="mt-2">
                        <p className="text-sm text-gray-200">
                          Analyzing the target site with AI and determining the nearest vector-encoded placements. This may take a few moments.
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Guest Post Inventory Search
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form className="space-y-6" onSubmit={handleContinue}>
            <div>
              <label
                  htmlFor="websiteURL"
                  className="block text-sm font-medium leading-6 text-gray-900 text-left"
              >
                Website URL
              </label>
              <div className="mt-2">
                <div
                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                    https://
                  </span>
                  <input
                      type="text"
                      name="company-website"
                      id="company-website"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="www.example.com"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {!userLoggedIn && (
            <div>
              <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 text-left"
              >
                Password
              </label>
              <div className="mt-2">
                <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            )}

            <div>
              <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Find Available Placements
              </button>
            </div>
          </form>
        </div>
      </div>
      {errorModalOpen && (
          <ErrorModal
              onClose={() => setErrorModalOpen(false)}
              title="Error"
              alertMessage={errorModalMessage}
              buttonText="OK"
          />
      )}
    </div>
  );
}
