import React, { createContext, useState, useContext, useEffect } from 'react';

// Create Context
const ApiDataContext = createContext();

export const ApiDataProvider = ({ children }) => {
    const [apiResponse, setApiResponse] = useState(null);
    const [url, setUrl] = useState('');
    const [password, setPassword] = useState('');
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState("");
    const [userLoggedIn, setUserLoggedIn] = useState(false);

    const fullUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;

    useEffect(() => {
        const isLoggedIn = document.cookie.includes('loggedIn=');
        setUserLoggedIn(isLoggedIn);
        const pw = document.cookie.split('loggedIn=')[1];
        if(pw) {
            setPassword(pw);
        }
    }, []);

    const fetchApiData = async () => {
        const apiUrl = "https://contentmanager.io/api/hoth/find-inventory?page=1";
        const payLoad = {
            "target_link": fullUrl,
            "pagesize": 200,
            "content_price": 48.5,
            "r_price": 250,
            "sort": "-relevancy_score",
            "suggestedOnly": "yes",
            "total_price": 75,
            "words_length": 1500,
            "SiteSearch": {
                "exclude_site_id": 358223,
                //"max_post_price": 125,
                //"min_moz_da": 30,
                //"min_semrush_traffic": 1000,
            }
        };
        // console.log('payLoad:', payLoad);

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization': 'Basic ' + btoa('admin:' + password),
                },
                body: JSON.stringify(payLoad)
            });
            const data = await response.json();
            if (response.ok) {
                document.cookie = 'loggedIn=true';
                setUserLoggedIn(true);
                setApiResponse(data);
                console.log('API response:', data)
                return true;
            }
            if (!response.ok) {
                let message = `HTTP error! status: ${response.status}`;
                if (response.status === 500) {
                    message = 'Internal server error';
                } else if (response.status === 401) {
                    message = 'Unauthorized: Incorrect password';
                }
                setErrorModalMessage(message);
                setErrorModalOpen(true);
                return false;
            }
     };

    return (
        <ApiDataContext.Provider value={{
            apiResponse, setApiResponse,
            url, setUrl, fullUrl,
            password, setPassword,
            errorModalMessage, setErrorModalMessage,
            errorModalOpen, setErrorModalOpen,
            userLoggedIn, setUserLoggedIn,
            fetchApiData
        }}>
            {children}
        </ApiDataContext.Provider>
    );
};


// Custom hook to use the context
export const useApiData = () => useContext(ApiDataContext);
