import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition, Switch, Menu } from "@headlessui/react";
import { useApiData } from "../Context/ApiDataContext";
import {
  XMarkIcon,
  AdjustmentsHorizontalIcon, ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  CurrencyDollarIcon,
  MapPinIcon,
  PencilIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import {SparklesIcon, StarIcon} from "@heroicons/react/16/solid";
import {ArrowUturnLeftIcon, BackwardIcon} from "@heroicons/react/24/solid";

const ApiOutputTable = () => {
  const { apiResponse, url } = useApiData();
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filteredData, setFilteredData] = useState(apiResponse);
  const [searchWebsite, setSearchWebsite] = useState("");
  const [searchPublisher, setSearchPublisher] = useState("");
  const [minDA, setMinDA] = useState("");
  const [minDR, setMinDR] = useState("");
  const [minSemrushTraffic, setMinSemrushTraffic] = useState("");
  const [maxCost, setMaxCost] = useState("");
  let [isOpen, setIsOpen] = useState(false);
  console.log("apiResponse:", apiResponse);

  const applyFilters = (e) => {
    e.preventDefault();
    // filter the data here
    const filtered = apiResponse.filter((item) => {
      const matchesWebsite = searchWebsite
        ? item.domain.toLowerCase().includes(searchWebsite.toLowerCase())
        : true;
      const matchesPublisher = searchPublisher
        ? item.publisherName
            .toLowerCase()
            .includes(searchPublisher.toLowerCase())
        : true;
      const matchesDA = minDA !== "" ? item.moz_da >= Number(minDA) : true;
      const matchesDR = minDR !== "" ? item.ahrefsdr >= Number(minDR) : true;
      const matchesSemrushTraffic = minSemrushTraffic
        ? item.semrush_organic_traffic >= Number(minSemrushTraffic)
        : true;
      const matchesCost = maxCost ? item.totalPrice <= Number(maxCost) : true;

      return (
        matchesWebsite &&
        matchesPublisher &&
        matchesDA &&
        matchesDR &&
        matchesSemrushTraffic &&
        matchesCost
      );
    });

    console.log("filteredData:", filtered);
    setFilteredData(filtered);
    setFiltersApplied(true);
    closeModal();
  };

  useEffect(() => {
    console.log("Filters Applied State Updated:", filtersApplied);
  }, [filtersApplied]);

  useEffect(() => {
    setFilteredData(apiResponse);
  }, [apiResponse]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function round(num) {
    return Math.ceil(num * 100) / 100;
  }

  function shortenParagraph(paragraph) {
    if (!paragraph) {
      return "";
    }

    // If paragraph is shorter than 200 characters, return it as is
    if (paragraph.length <= 200) {
      return paragraph;
    }

    // If paragraph is longer, return the first 200 characters followed by "..."
    return paragraph.substring(0, 200) + "...";
  }

  function matchStatus(value) {
    if (value >= 80) {
      return "text-green-400 bg-green-400/10";
    } else if (value >= 60) {
      return "text-yellow-400 bg-yellow-400/10";
    } else {
      return "text-rose-400 bg-rose-400/10";
    }
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [enabled, setEnabled] = useState(true);
  return (
    <div className="py-12">
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <form
                      onSubmit={applyFilters}
                      className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                    >
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-base font-semibold leading-6 text-white">
                              Filter Options
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={closeModal}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              Drill down the placement list by using the filters
                              below.
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <label
                                  htmlFor="searchWebsite"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Search by website
                                </label>
                                <div className="mt-2">
                                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                      https://
                                    </span>
                                    <input
                                      type="text"
                                      name="searchWebsite"
                                      id="searchWebsite"
                                      placeholder="https://"
                                      value={searchWebsite}
                                      onChange={(e) =>
                                        setSearchWebsite(e.target.value)
                                      }
                                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                      placeholder="www.example.com"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="searchPublisher"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Search by publisher
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    name="searchPublisher"
                                    id="searchPublisher"
                                    placeholder="Publisher name"
                                    value={searchPublisher}
                                    onChange={(e) =>
                                      setSearchPublisher(e.target.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                <div className="sm:col-span-3">
                                  <label
                                    htmlFor="minDA"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Min DA
                                  </label>
                                  <div className="mt-2">
                                    <select
                                      id="minDA"
                                      name="minDA"
                                      autoComplete="minDA"
                                      value={minDA}
                                      onChange={(e) => setMinDA(e.target.value)}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    >
                                      <option value="">Select Min DA</option>
                                      <option value="30">30+</option>
                                      <option value="40">40+</option>
                                      <option value="50">50+</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="sm:col-span-3">
                                  <label
                                    htmlFor="minDR"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Min DR
                                  </label>
                                  <div className="mt-2">
                                    <select
                                      id="minDR"
                                      name="minDR"
                                      autoComplete="minDR"
                                      value={minDR}
                                      onChange={(e) => setMinDR(e.target.value)}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    >
                                      <option value="">Select Min DR</option>
                                      <option value="30">30+</option>
                                      <option value="40">40+</option>
                                      <option value="50">50+</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                <div className="sm:col-span-3">
                                  <label
                                    htmlFor="minSemrushTraffic"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Min Semrush Traffic
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      name="minSemrushTraffic"
                                      id="minSemrushTraffic"
                                      value={minSemrushTraffic}
                                      onChange={(e) =>
                                        setMinSemrushTraffic(e.target.value)
                                      }
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                </div>
                                <div className="sm:col-span-3">
                                  <label
                                    htmlFor="maxCost"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Max Cost
                                  </label>
                                  <div className="relative mt-2 rounded-md shadow-sm">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                      <span className="text-gray-500 sm:text-sm">
                                        $
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      name="maxCost"
                                      id="maxCost"
                                      value={maxCost}
                                      onChange={(e) =>
                                        setMaxCost(e.target.value)
                                      }
                                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      placeholder="0.00"
                                      aria-describedby="maxCost"
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                      <span
                                        className="text-gray-500 sm:text-sm"
                                        id="maxCost"
                                      >
                                        USD
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="space-y-6 pb-5 pt-6">
                              <Switch.Group
                                as="div"
                                className="flex items-center justify-between"
                              >
                                <span className="flex flex-grow flex-col">
                                  <Switch.Label
                                    as="span"
                                    className="text-sm font-medium leading-6 text-gray-900"
                                    passive
                                  >
                                    Show Site Summaries
                                  </Switch.Label>
                                </span>
                                <Switch
                                  checked={enabled}
                                  onChange={() => setEnabled(prevEnabled => !prevEnabled)}
                                  className={classNames(
                                    enabled ? "bg-indigo-600" : "bg-gray-200",
                                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                  )}
                                >
                                  <span className="sr-only">Use setting</span>
                                  <span
                                    className={classNames(
                                      enabled
                                        ? "translate-x-5"
                                        : "translate-x-0",
                                      "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                    )}
                                  >
                                    <span
                                      className={classNames(
                                        enabled
                                          ? "opacity-0 duration-100 ease-out"
                                          : "opacity-100 duration-200 ease-in",
                                        "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                                      )}
                                      aria-hidden="true"
                                    >
                                      <svg
                                        className="h-3 w-3 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 12 12"
                                      >
                                        <path
                                          d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    <span
                                      className={classNames(
                                        enabled
                                          ? "opacity-100 duration-200 ease-in"
                                          : "opacity-0 duration-100 ease-out",
                                        "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                                      )}
                                      aria-hidden="true"
                                    >
                                      <svg
                                        className="h-3 w-3 text-indigo-600"
                                        fill="currentColor"
                                        viewBox="0 0 12 12"
                                      >
                                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                      </svg>
                                    </span>
                                  </span>
                                </Switch>
                              </Switch.Group>
                            </div>
                            <div className="pb-6 pt-4"></div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Apply Filters
                        </button>
                      </div>
                    </form>

                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Filter Settings
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={closeModal}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {/* Your content */}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* <h1>Url: {targetLink}</h1> */}
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-16">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-shrink-0">
            <Link to="/">
              <ArrowUturnLeftIcon className="m-5 h-5 w-5 text-gray-400" />
            </Link>
          </div>
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight text-left">
              Available Placements
            </h2>
            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <LinkIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {url}
              </div>
              {apiResponse && apiResponse[0].relevancy_score === null && (
                  <div className="text-orange-500 mt-2 flex items-center text-sm">
                    <ExclamationTriangleIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0"
                        aria-hidden="true"
                    />
                    <p>We were not able to scrape this site and calculate vector relevancy.</p>
                  </div>
              )}

            </div>
          </div>
          <div className="mt-5 flex lg:ml-4 lg:mt-0">
            <span className="sm:ml-3">
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={openModal}
              >
                <AdjustmentsHorizontalIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                Filter Options
              </button>
            </span>

            {/* Dropdown */}
            <Menu as="div" className="relative ml-3 sm:hidden">
              <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                More
                <ChevronDownIcon
                  className="-mr-1 ml-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Edit
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        View
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-6">
        {apiResponse && apiResponse.length > 0 ? (
            <table className="table-fixed min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
              <tr className="text-xs font-semibold text-gray-900">
              <th className="py-3.5 pl-4 pr-3 text-left border-r border-gray-200">
                Site
              </th>
              {/*<th className="py-3.5 pl-4 pr-3 text-left">Niche</th>*/}
              <th className="py-3.5 pl-4 pr-3 text-center">Price</th>
              <th className="py-3.5 pl-4 pr-3 text-center">
                Margin
              </th>
              {apiResponse && apiResponse[0].relevancy_score && (
              <th className="py-3.5 pl-4 pr-3 text-center">Relevancy</th>
                  )}
              <th className="py-3.5 pl-4 pr-3 text-center">Publisher</th>
              {/*<th className="py-3.5 pl-4 pr-3 text-left">Link Type</th>*/}
              {/* <th className="py-3.5 pl-4 pr-3 text-left">
                  Site Description
                </th> */}
              {/*<th className="py-3.5 pl-4 pr-3 text-left">Placements Sent</th>*/}
              {/*<th className="py-3.5 pl-4 pr-3 text-left">Assigned Jobs</th>*/}
              {enabled && (
              <th className="py-3.5 pl-4 pr-3 text-left" style={{width: '33%'}}>Summary</th>
              )}
            </tr>
            </thead>
            <tbody>
            {(filtersApplied ? filteredData : apiResponse).map(
                (item, index) => (
                  <tr key={index} className="even:bg-gray-50">
                    {/*site*/}
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0 border-r border-gray-200">
                      <div className="text-left px-4">
                        <a
                          className="block mb-2 text-gray-800 hover:text-blue-800"
                          href={item.url}
                        >
                          {item.domain}
                        </a>
                        <div className="flex gap-1">
                          <div className="flex text-xs py-0.5 pr-1 pl-2 rounded-md border border-gray-300 gap-1 text-gray-700 font-medium bg-white">
                            <div>DA</div>
                            <div className="bg-gray-100 px-1">
                              {item.moz_da}
                            </div>
                          </div>
                          <div className="flex text-xs py-0.5 pr-1 pl-2 rounded-md border border-gray-300 gap-1 text-gray-700 font-medium bg-white">
                            <div>DR</div>
                            <div className="bg-gray-100 px-1">
                              {item.ahrefsdr}
                            </div>
                          </div>
                          <div className="flex text-xs py-0.5 pr-1 pl-2 rounded-md border border-gray-300 gap-1 text-gray-700 font-medium bg-white">
                            <div>Semrush</div>
                            <div className="bg-gray-100 px-1">
                              {item.semrush_organic_traffic}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    {/*Industry / niche*/}
                    {/* <td>{item.niche}</td> */}
                    {/*<td className="px-4 text-sm text-left">Technology</td>*/}

                    {/*Placement Price*/}
                    <td className="px-4 text-sm text-right">
                      ${item.totalPrice.toFixed(0)}
                    </td>

                    {/*Placement Margin %*/}
                    <td className="px-4 text-sm text-right">
                      <div className="flex items-center justify-end gap-x-2 sm:justify-end">
                        <div className="hidden sm:block">
                          {/* {item.totalPricePerc}% */}
                          {item.totalPricePerc.toFixed(0)}%
                        </div>
                        <div
                          className={
                            "flex-none rounded-full p-1 " +
                            matchStatus(item.totalPricePerc)
                          }
                        >
                          <div className="h-1.5 w-1.5 rounded-full bg-current"></div>
                        </div>
                      </div>
                    </td>
                    {/*Match Relevancy*/}
                    {apiResponse && apiResponse[0].relevancy_score && (
                        <td className="px-4 text-sm text-right">
                      <div className="flex items-center justify-end gap-x-2 sm:justify-end">
                        {item.relevancy_score !== null && ( // Only render if relevancy_score is not null
                        <div className="hidden sm:block">
                          {item.relevancy_score}%
                        </div>
                        )}
                        {item.relevancy_score !== null && ( // Only render if relevancy_score is not null
                            <div
                                className={`flex-none rounded-full p-1 ${matchStatus(item.relevancy_score)}`}
                            >
                              <div className="h-1.5 w-1.5 rounded-full bg-current"></div>
                            </div>
                        )}
                      </div>
                    </td>
                    )}
                    {/*Publisher*/}
                    <td className="px-4 text-sm text-left">
                      {item.publisherName}
                      {item.lastMonthUserAssignedJobs > 100 && item.lastMonthUserAssignedJobs < 300 && (
                          <StarIcon className="w-3 h-3 inline ml-2 text-yellow-200"/>
                      )}
                      {item.lastMonthUserAssignedJobs > 300 && (
                          <SparklesIcon className="w-4 h-4 inline ml-2 text-yellow-300"/>
                      )}
                      {item.lastMonthUserAssignedJobs > 0 && (
                          <div className="text-xs text-gray-400">{item.lastMonthUserAssignedJobs} recent jobs</div>
                    )}
                      {(!item.lastMonthUserAssignedJobs || item.lastMonthUserAssignedJobs === 0) && (
                          <div className="text-xs text-orange-200"><ExclamationTriangleIcon className="w-3 h-3 inline mr-1"/>New publisher</div>
                          )}
                    </td>
                    {/*AI Summary*/}
                    {enabled && (
                        <td className="px-4 text-sm text-left text-gray-600">
                          {shortenParagraph(item.ai_summary ?? item.description)}
                        </td>
                    )}
                    {/*Link Type*/}
                    {/*<td>{item.linkType}</td>*/}
                    {/*Site Description / Target audience / Special Instructions*/}
                    {/* <td className="px-4 text-left text-sm">{item.description}</td> */}

                    {/*# of Placements Sent*/}
                    {/*<td className="px-4 text-sm">*/}
                    {/*  <div className="mb-1 text-left">*/}
                    {/*    {item.lastMonthJobs} of {item.lastMonthUserAssignedJobs}*/}
                    {/*  </div>*/}
                    {/*  <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">*/}
                    {/*    <div*/}
                    {/*      className="bg-green-400 h-1.5 rounded-full"*/}
                    {/*      style={{*/}
                    {/*        width:*/}
                    {/*          calcPercentage(*/}
                    {/*            item.lastMonthJobs,*/}
                    {/*            item.lastMonthUserAssignedJobs*/}
                    {/*          ) + "%",*/}
                    {/*      }}*/}
                    {/*    ></div>*/}
                    {/*  </div>*/}
                    {/*</td>*/}

                    {/*Publisher Assigned jobs this month*/}
                    {/*<td className="px-4 text-sm text-left">*/}
                    {/*  {item.lastMonthUserAssignedJobs}*/}
                    {/*</td>*/}
                  </tr>
                )
              )}
            </tbody>
          </table>
        ) : (
          <div className="text-center">
            <div className="mt-6">
              <a
                href="/"
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Search Placements
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApiOutputTable;
