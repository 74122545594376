import React, { useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';

function ErrorModal({ onClose, title, alertMessage, buttonText = 'OK' }) {
    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show as="div" className="relative z-50">
            <Dialog as="div" initialFocus={cancelButtonRef} onClose={onClose}>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as="div"
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:scale-95"
                        >
                            <Dialog.Panel className="transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        {/*<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">*/}
                                        {/*    {title}*/}
                                        {/*</Dialog.Title>*/}
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {alertMessage}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={onClose}
                                        ref={cancelButtonRef}
                                    >
                                        {buttonText}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

ErrorModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    alertMessage: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
};

export default ErrorModal;
